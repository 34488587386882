import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { toast } from 'react-toastify';

export interface ProviderCommitment {
  id?: string;
  name: string;
  commitment_gb_per_month: number;
  average_object_size_bytes: number | null;
  account_providers: string[];
}

export interface ProviderCommitmentTrafficPoint {
  timestamp: number;
  value: number;
}
export interface ProviderCommitmentTraffic {
  error: string | null;
  accumulatedBytes: ProviderCommitmentTrafficPoint[];
  accumulatedAverageObjectSize: ProviderCommitmentTrafficPoint[];
}

export interface ProviderCommitmentState {
  list: ProviderCommitment[];
  updating: ProviderCommitment | null;
  error: string | null;
  traffic: ProviderCommitmentTraffic;
}

const initialState: ProviderCommitmentState = {
  list: [],
  updating: null,
  error: null,
  traffic: { error: null, accumulatedBytes: [], accumulatedAverageObjectSize: [] },
};

const slice = createSlice({
  name: 'providerCommitments',
  initialState,
  reducers: {
    addUpdatingState: (state) => {
      state.error = null;
      state.updating = {
        name: 'new commitment',
        commitment_gb_per_month: 0,
        average_object_size_bytes: null,
        account_providers: [],
      };
    },
    editUpdatingState: (state, action) => {
      state.updating = action.payload;
      state.error = null;
    },
    providerCommitmentsReceived: (state, action) => {
      state.list = action.payload[0];
      state.error = null;
    },
    providerCommitmentAdded: (state, action) => {
      state.list.push(action.payload[0]);
      state.updating = null;
      state.error = null;
    },
    providerCommitmentUpdated: (state, action) => {
      state.list = state.list.map((p) => (p.id === action.payload[0].id ? action.payload[0] : p));
      state.updating = null;
      state.error = null;
    },
    providerCommitmentDeleted: (state, action) => {
      const deleted = action.payload[1].payload.id;
      state.list = state.list.filter((s) => {
        return s.id !== deleted;
      });
      state.error = null;
    },
    onError: (state, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      state.error = msg;
    },
    providerCommitmentTrafficReceived: (state, action) => {
      state.traffic = action.payload[0];
      if (state.traffic.error) {
        toast.error(state.traffic.error);
      }
    },
  },
});

export const {
  addUpdatingState,
  editUpdatingState,
  providerCommitmentsReceived,
  providerCommitmentAdded,
  providerCommitmentUpdated,
  providerCommitmentDeleted,
  onError,
  providerCommitmentTrafficReceived,
} = slice.actions;
export default slice.reducer;

export const loadProviderCommitments = () =>
  apiCallBegan({
    url: '/api/v1/account-provider-commitment/',
    onSuccess: providerCommitmentsReceived.type,
    onError: onError.type,
  });

export const addProviderCommitment = (p: ProviderCommitment) =>
  apiCallBegan({
    url: `/api/v1/account-provider-commitment/`,
    method: 'post',
    onSuccess: providerCommitmentAdded.type,
    data: p,
    onError: onError.type,
  });

export const updateProviderCommitment = (id: string, p: ProviderCommitment) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/account-provider-commitment/${id}/`,
    method: 'put',
    data: p,
    onSuccess: providerCommitmentUpdated.type,
    onError: onError.type,
  });

export const deleteProviderCommitment = (id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/account-provider-commitment/${id}/`,
    method: 'delete',
    onSuccess: providerCommitmentDeleted.type,
    onError: onError.type,
  });

export const getCommitmentTraffic = (id: string, month: string, year: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v2/traffic/commitment_traffic/${id}?month=${month}&year=${year}`,
    method: 'get',
    onSuccess: providerCommitmentTrafficReceived.type,
    onError: onError.type,
  });
