import React from 'react';
import { connect } from 'react-redux';
import IButton from '../../common/IButton';
import checkMark from '../../../assets/icons/form_checkmark.svg';
import { textDict } from '../../../utils/text-utils';

class AcceptAccountAccountInvitation extends React.Component {
  state = {};

  schema = {};

  render() {
    return (
      <div className="register-background">
        <div className="view-container">
          <div className="email-verified-container">
            <div className={'email-verified-message'}>
              <img src={checkMark} alt={''} />
              <h1>{textDict.invitationAccepted}</h1>
            </div>
            <IButton
              type="button"
              variant="primary"
              onClick={() => {
                document.location = '/';
              }}
            >
              {textDict.iButtonLoginNormal}
            </IButton>
          </div>
        </div>
      </div>
    );
  }
}

AcceptAccountAccountInvitation.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AcceptAccountAccountInvitation);
