import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface Purge {
  paths: string;
  serviceId: string;
  status: string;
  timestamp: number;
  extended_status: string;
}

interface PurgeState {
  list: Purge[];
  loading: boolean;
  lastFetch: number | null;
  lastChange: number | null;
  purgePath: string;
  error: string | null;
}

const initialState: PurgeState = {
  list: [],
  loading: false,
  lastFetch: null,
  lastChange: null,
  purgePath: '',
  error: null,
};

const slice = createSlice({
  name: 'purges',
  initialState,
  reducers: {
    // actions => action handlers

    purgesReceived: (purges, action) => {
      purges.list = action.payload[0];
      purges.loading = false;
      purges.lastFetch = Date.now();
    },

    purgesAdded: (purges, action) => {
      purges.purgePath = '';
      purges.error = null;
      purges.lastChange = new Date().getTime();
    },

    clearError: (purges, action) => {
      purges.error = null;
    },

    setPurgePath: (purges, action) => {
      purges.purgePath = action.payload;
    },

    onError: (purges, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      purges.error = msg;
    },
  },
});

export const { purgesReceived, purgesAdded, clearError, setPurgePath, onError } = slice.actions;
export default slice.reducer;

// Action Creators

export const loadPurgesNew = (s: string, startTime: number, endTime: number) =>
  apiCallBegan({
    url: `/api/v1/purges/${s}?start_time=${startTime}&end_time=${endTime}`,
    onSuccess: purgesReceived.type,
  });

export const purgeNew = (s: string, patterns: string) =>
  apiCallBegan({
    url: `/api/v1/purges/${s}/purge`,
    method: 'post',
    data: { patterns: patterns },
    onSuccess: purgesAdded.type,
    onError: onError.type,
  });

export const purgeAllNew = (s: string) =>
  apiCallBegan({
    url: `/api/v1/purges/${s}/purge-all`,
    method: 'post',
    onSuccess: purgesAdded.type,
    onError: onError.type,
  });
