import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { ServiceOrigin } from './serviceOrigins';

type DestinationType = 'O' | 'LB' | '';

interface DomainMapping {
  target_type: 'origin';
  target_id: string;
  path_pattern: string;
  origin: ServiceOrigin;
}

interface ServiceDomain {
  id: string;
  service: string;
  domain: string;
  aliases: string[];
  mappings: DomainMapping[];
}

interface Destination {
  id: string | undefined;
  paths: Array<{ path: string; domain: ServiceDomain }>;
  name: string;
  type: DestinationType;
  origin: ServiceOrigin;
}

type Group = {
  domain: ServiceDomain;
  destinations: { [key: string]: Destination };
  destinationsList?: Destination[];
};

type Groups = { [key: string]: Group };

interface ServiceDomainState {
  list: ServiceDomain[];
  grouped: Group[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  pendingDomain: ServiceDomain | null;
  pendingPath: string | null;
  pendingDomainForPath: ServiceDomain | null;
  pendingAlias: string | null;
  pendingDomainForAlias: ServiceDomain | null;
  pendingPathDestination: string | null;
  pendingDomainForDestination: ServiceDomain | null;
  updating: boolean;
  updatingPath: boolean;
  updatingPathDestination: boolean;
  updatingAlias: boolean;
}

let group = (serviceDomains: ServiceDomainState) => {
  let groups: Groups = {};
  for (let domain of serviceDomains.list) {
    for (let domainMapping of domain.mappings) {
      let type = '';
      let key = domain.domain;
      let destination_id;
      let destinationName;

      type = 'O';
      destination_id = domainMapping.target_id;
      if (domainMapping.origin.type === 'CUSTOM') {
        destinationName = domainMapping.origin.host;
        if (domainMapping.origin.protocol === 'HTTPS' && domainMapping.origin.https_port !== 443) {
          destinationName += ':' + domainMapping.origin.https_port;
        } else if (
          domainMapping.origin.protocol === 'HTTP' &&
          domainMapping.origin.http_port !== 80
        ) {
          destinationName += ':' + domainMapping.origin.http_port;
        }
      } else {
        destinationName = domainMapping.origin.service_provider.name;
      }

      if (!groups[key]) {
        groups[key] = {
          domain: domain,
          destinations: {},
        };
      }

      if (!groups[key].destinations[destination_id]) {
        groups[key].destinations[destination_id] = {
          id: destination_id,
          paths: [],
          name: destinationName,
          type: type as DestinationType,
          origin: domainMapping.origin,
        };
      }

      let path_pattern = domainMapping.path_pattern;
      if (!path_pattern) {
        path_pattern = '/*';
      }
      groups[key].destinations[destination_id].paths.push({
        path: path_pattern,
        domain: domain,
      });
    }

    serviceDomains.grouped = [];

    for (const [key, val] of Object.entries(groups)) {
      val.destinationsList = [];
      for (const [key, destination] of Object.entries(val.destinations)) {
        if (destination) {
          val.destinationsList.push(destination);
        }
      }
      serviceDomains.grouped.push(val);
    }
    serviceDomains.loading = false;
    serviceDomains.lastFetch = Date.now();
  }
};

const initialState: ServiceDomainState = {
  list: [],
  grouped: [],
  loading: false,
  lastFetch: null,
  error: null,
  pendingDomain: null,
  pendingPath: null,
  pendingPathDestination: null,
  updating: false,
  updatingPath: false,
  updatingPathDestination: false,
  updatingAlias: false,
  pendingAlias: null,
  pendingDomainForAlias: null,
  pendingDomainForPath: null,
  pendingDomainForDestination: null,
};

const slice = createSlice({
  name: 'serviceDomainsNew',
  initialState,
  reducers: {
    // actions => action handlers

    serviceDomainsReceived: (serviceDomains, action) => {
      serviceDomains.list = action.payload[0];
      group(serviceDomains);
    },

    serviceDomainAdded: (serviceDomains, action) => {
      serviceDomains.list.push(action.payload[0]);
      group(serviceDomains);
      serviceDomains.error = null;
      serviceDomains.pendingDomain = null;
      serviceDomains.pendingPath = null;
      serviceDomains.updating = false;
      serviceDomains.updatingPath = false;
      serviceDomains.pendingPathDestination = null;
      serviceDomains.updatingPathDestination = false;
      serviceDomains.pendingDomainForAlias = null;
      serviceDomains.pendingDomainForPath = null;
    },

    serviceDomainDeleted: (serviceDomains, action) => {
      const deleted = action.payload[1].payload.id;
      serviceDomains.list = serviceDomains.list.filter((p) => p.id !== deleted);
      group(serviceDomains);
      serviceDomains.error = null;
    },

    serviceDomainUpdated: (serviceDomains, action) => {
      serviceDomains.list = serviceDomains.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      group(serviceDomains);
      serviceDomains.error = null;
      serviceDomains.pendingDomain = null;
      serviceDomains.pendingPath = null;
      serviceDomains.updating = false;
      serviceDomains.updatingPath = false;
      serviceDomains.pendingPathDestination = null;
      serviceDomains.updatingPathDestination = false;
      serviceDomains.pendingDomainForPath = null;
      serviceDomains.pendingDomainForAlias = null;
    },

    domainsError: (serviceDomains, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      serviceDomains.error = msg;
    },

    updatePendingDomain: (serviceDomains, action: PayloadAction<ServiceDomain>) => {
      serviceDomains.pendingDomain = action.payload;
      serviceDomains.updating = Object.keys(action.payload).length > 0;
      serviceDomains.error = null;
    },

    updatePendingPath: (serviceDomains, action) => {
      serviceDomains.pendingPath = action.payload;
      serviceDomains.updatingPath = serviceDomains.pendingPath !== null;
      serviceDomains.error = null;
    },

    updatePendingPathDestination: (serviceDomains, action) => {
      serviceDomains.pendingPathDestination = action.payload;
      serviceDomains.updatingPathDestination = serviceDomains.pendingPathDestination !== null;
      serviceDomains.error = null;
      if (serviceDomains.pendingDomainForDestination) {
        window.location.hash = serviceDomains.pendingDomainForDestination.id || '';
      }
    },

    updatePendingAlias: (serviceDomains, action) => {
      serviceDomains.updatingAlias = action.payload !== null;
      serviceDomains.pendingAlias = action.payload;
    },

    updatePendingDomainForAlias: (serviceDomains, action) => {
      serviceDomains.pendingDomainForAlias = action.payload;
    },

    updatePendingDomainForPath: (serviceDomains, action) => {
      serviceDomains.pendingDomainForPath = action.payload;
    },

    updatePendingDomainForDestination: (serviceDomains, action) => {
      serviceDomains.pendingDomainForDestination = action.payload;
    },

    setError: (serviceDomains, action) => {
      serviceDomains.error = action.payload;
    },
  },
});

export const {
  serviceDomainsReceived,
  serviceDomainAdded,
  serviceDomainDeleted,
  domainsError,
  updatePendingDomain,
  serviceDomainUpdated,
  updatePendingAlias,
  updatePendingPath,
  updatePendingDomainForAlias,
  updatePendingDomainForPath,
  updatePendingDomainForDestination,
  updatePendingPathDestination,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadServiceDomains = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/domains/`,
    onSuccess: serviceDomainsReceived.type,
    onError: domainsError.type,
  });

export const addServiceDomain = (s: string, o: ServiceDomain) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/domains/`,
    method: 'post',
    data: o,
    onSuccess: serviceDomainAdded.type,
    onError: domainsError.type,
  });

export const updateServiceDomain = (s: string, d: ServiceDomain, id: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/domains/${id}/`,
    method: 'put',
    data: d,
    onSuccess: serviceDomainUpdated.type,
    onError: domainsError.type,
  });

export const deleteServiceDomain = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v1/services/${s}/domains/${o}/`,
    method: 'delete',
    onSuccess: serviceDomainDeleted.type,
    onError: domainsError.type,
  });
