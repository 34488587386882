import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

export interface ServiceProvider {
  id: string;
  account_provider: string;
  service: string;
  status: string;
  status_details: string;
  restored: boolean;
  cname: string;
  name: string;
  display_name: string;
  akamai_amd_stream: string;
  use_akamai_media_services: boolean;
  weight?: number;
}

type ProviderPerServiceType = { [key: string]: ServiceProvider[] };

export interface ServiceProviderState {
  providersPerService: ProviderPerServiceType;
  list: ServiceProvider[];
  loading: boolean;
  lastFetch: number | null;
  updating: boolean;
  deleted: string[];
  error?: string | null;
}

const initialState: ServiceProviderState = {
  providersPerService: {},
  list: [],
  loading: false,
  lastFetch: null,
  updating: false,
  deleted: [],
};

const slice = createSlice({
  name: 'serviceProviders',
  initialState,
  reducers: {
    // actions => action handlers

    serviceProvidersReceived: (serviceProviders, action) => {
      if (serviceProviders.updating) {
        return;
      }

      serviceProviders.list = (action.payload[0] as ServiceProvider[])
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((s) => s.status !== 'Deleting' && serviceProviders.deleted.indexOf(s.id) === -1);
      serviceProviders.loading = false;
      serviceProviders.lastFetch = Date.now();
      if (serviceProviders.list.length > 0) {
        serviceProviders.providersPerService[serviceProviders.list[0].service] =
          serviceProviders.list;
      }
      // if (serviceProviders.list.length > 0) {
      //   serviceProviders.list.push(serviceProviders.list[0]);
      //   serviceProviders.list.push(serviceProviders.list[0]);
      //   serviceProviders.list.push(serviceProviders.list[0]);
      // }
    },

    serviceProviderAdded: (serviceProviders, action) => {
      serviceProviders.error = null;
      serviceProviders.list.push(action.payload[0]);
    },

    serviceProviderWeightUpdated: (serviceProviders, action) => {
      const index = serviceProviders.list.findIndex(
        (provider) => provider.id === action.payload[0].id,
      );
      serviceProviders.list[index].weight = action.payload[0].weight;
      serviceProviders.updating = false;
    },

    serviceProviderDeleted: (serviceProviders, action) => {
      const deleted = action.payload[1].payload.id;
      serviceProviders.list = serviceProviders.list.filter((p) => p.id !== deleted);
      serviceProviders.deleted.push(deleted);
    },

    serviceProviderWeightUpdating: (serviceProviders, action) => {
      serviceProviders.updating = true;
      const index = serviceProviders.list.findIndex(
        (provider) => provider.id === action.payload[0].id,
      );
      serviceProviders.list[index].weight = action.payload[0].weight;
    },

    clearProviders: (serviceProviders, action) => {
      serviceProviders.list = [];
    },

    onError: (serviceProviders, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      serviceProviders.error = msg;
    },
  },
});

export const {
  serviceProvidersReceived,
  serviceProviderAdded,
  serviceProviderWeightUpdated,
  serviceProviderDeleted,
  serviceProviderWeightUpdating,
  onError,
  clearProviders,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadServiceProviders = (s: string, bypass?: boolean) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/providers/`,
    onSuccess: serviceProvidersReceived.type,
    onError: onError.type,
    bypassThrottle: bypass,
  });

export const addServiceProvider = (s: string, p: ServiceProvider) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/providers/`,
    method: 'post',
    data: p,
    onSuccess: serviceProviderAdded.type,
    onError: onError.type,
  });

export const deleteServiceProvider = (s: string, p: string, force: boolean) =>
  apiCallBegan({
    id: p,
    url: `/api/v1/services/${s}/providers/${p}/?force=${force}`,
    method: 'delete',
    onSuccess: serviceProviderDeleted.type,
    onError: onError.type,
  });
