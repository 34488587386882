import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { durationDisplayName, DurationUnits } from '../model/duration';
import { validateIPAddress } from 'src/utils/validators';
import { getNonNegativeValue } from 'src/utils/text-utils';
import { textDict } from 'src/utils/text-utils';

type BehaviorActionType =
  | 'SET_RESPONSE_HEADER'
  | 'CACHE_TTL'
  | 'CACHE_BEHAVIOR'
  | 'BROWSER_CACHE_TTL'
  | 'REDIRECT'
  | 'ORIGIN_CACHE_CONTROL'
  | 'BYPASS_CACHE_ON_COOKIE'
  | 'CACHE_KEY'
  | 'AUTO_MINIFY'
  | 'HOST_HEADER_OVERRIDE'
  | 'SET_CORS_HEADER'
  | 'FOLLOW_REDIRECTS'
  | 'STATUS_CODE_CACHE'
  | 'GENERATE_PREFLIGHT_RESPONSE'
  | 'STATUS_CODE_BROWSER_CACHE'
  | 'STALE_TTL'
  | 'STREAM_LOGS'
  | 'ALLOWED_METHODS'
  | 'COMPRESSION'
  | 'GENERATE_RESPONSE'
  | 'CACHED_METHODS'
  | 'SET_REQUEST_HEADER'
  | 'VIEWER_PROTOCOL'
  | 'DELETE_REQUEST_HEADER'
  | 'DELETE_RESPONSE_HEADER'
  | 'LARGE_FILES_OPTIMIZATION'
  | 'URL_SIGNING'
  | 'TRUE_CLIENT_IP'
  | 'DENY_ACCESS'
  | 'URL_REWRITE'
  | 'DENY_ACCESS_BY_TIME'
  | 'DENY_ACCESS_BY_IP';

export type CacheBehaviorValueType = 'CACHE' | 'BYPASS';

export interface DateTimeWindow {
  start_date: number;
  end_date: number;
}

export interface TimePeriodic {
  start_date: number | null;
  duration: number | null;
  duration_units: DurationUnits;
  repeat_period: number;
  repeat_period_units: DurationUnits;
}

export interface TimeConstraint {
  date_time_window: DateTimeWindow | null;
  time_periodic: TimePeriodic | null;
}

export interface IPList {
  ip_list: string[];
}

export interface BehaviorAction {
  id: string | null;
  type: BehaviorActionType;
  max_ttl: number | null;
  response_header_name: string | null;
  response_header_value: string | null;
  cache_behavior_value: CacheBehaviorValueType | null;
  redirect_url: string | null;
  redirect_source: string | null;
  origin_cache_control_enabled: boolean | null;
  pattern: string | null;
  cookie: string | null;
  auto_minify: string | null;
  host_header: string | null;
  use_domain_origin: boolean | null;
  origin: string | null;
  enabled: boolean | null;
  cache_key: string | null;
  cors_allow_origin_domain: boolean | null;
  status_code: number | null;
  unified_log_destination: string | null;
  unified_log_sampling_rate: number | null;
  allowed_methods: string | null;
  response_page_path: string | null;
  cached_methods: string | null;
  validationError?: ActionValidationError | null;
  request_header_name?: string | null;
  request_header_value?: string | null;
  generate_preflight_allowed_headers?: string | null;
  viewer_protocol: 'HTTP_AND_HTTPS' | 'HTTPS_ONLY' | 'REDIRECT_HTTP_TO_HTTPS' | null;
  url_rewrite_source?: string;
  url_rewrite_destination?: string;
  override_header_value?: boolean;
  deny_access_by_time: TimeConstraint;
  deny_access_by_ip: IPList;
}

type BehaviorActionLabelsMap = {
  [key in BehaviorActionType]: string;
};

export type StringMap = {
  [key: string]: string;
};

export const BehaviorActionTypes: BehaviorActionLabelsMap = {
  CACHE_BEHAVIOR: 'Cache Behavior',
  CACHE_TTL: 'Cache TTL',
  SET_RESPONSE_HEADER: 'Set Response Header',
  BROWSER_CACHE_TTL: 'Browser Cache TTL',
  REDIRECT: 'Redirect to URL',
  ORIGIN_CACHE_CONTROL: 'Origin Cache Control',
  BYPASS_CACHE_ON_COOKIE: 'Bypass Cache On Cookie',
  CACHE_KEY: 'Cache Key',
  AUTO_MINIFY: 'Auto Minify',
  HOST_HEADER_OVERRIDE: 'Host Header Override',
  SET_CORS_HEADER: 'Set CORS Header',
  FOLLOW_REDIRECTS: 'Follow Redirects',
  STATUS_CODE_CACHE: 'Status Code Cache',
  GENERATE_PREFLIGHT_RESPONSE: 'Generate Preflight Response',
  STATUS_CODE_BROWSER_CACHE: 'Status Code Browser Cache',
  STALE_TTL: 'Stale TTL',
  STREAM_LOGS: 'Stream Logs',
  ALLOWED_METHODS: 'Allowed Methods',
  COMPRESSION: 'Compression',
  GENERATE_RESPONSE: 'Generate Response',
  CACHED_METHODS: 'Cached Methods',
  SET_REQUEST_HEADER: 'Set Request Header',
  VIEWER_PROTOCOL: 'Viewer Protocol',
  DELETE_REQUEST_HEADER: 'Delete Request Header',
  DELETE_RESPONSE_HEADER: 'Delete Response Header',
  LARGE_FILES_OPTIMIZATION: 'Large Files Optimization',
  URL_SIGNING: 'URL Signing',
  TRUE_CLIENT_IP: 'True Client IP',
  DENY_ACCESS: 'Deny Access',
  URL_REWRITE: 'URL Rewrite',
  DENY_ACCESS_BY_TIME: 'Deny Access By Time',
  DENY_ACCESS_BY_IP: 'Deny Access By IP',
};

export interface QueryStringCacheKey {
  type: 'none' | 'whitelist' | 'blacklist' | 'all';
  list: string[];
}

export interface CacheKey {
  headers: string[];
  headersType: 'none' | 'include';
  cookies: string[];
  cookiesType: 'none' | 'include';
  query_strings: QueryStringCacheKey;
}

export interface ActionValidationError {
  [key: string]: string;
}

function statusCodeDisplay(statusCode: number): string {
  if (statusCode < 10) {
    return statusCode + 'xx';
  }
  return statusCode + '';
}

function getDenyAccessByTime(deny_access_by_time: TimeConstraint): string {
  if (deny_access_by_time.time_periodic) {
    return (
      'Duration - ' +
      deny_access_by_time.time_periodic.duration +
      ' ' +
      durationDisplayName(deny_access_by_time.time_periodic.duration_units) +
      ' every ' +
      deny_access_by_time.time_periodic.repeat_period +
      ' ' +
      durationDisplayName(deny_access_by_time.time_periodic.repeat_period_units) +
      ' starting from ' +
      new Date(deny_access_by_time.time_periodic!.start_date!)
    );
  } else {
    return (
      'From ' +
      new Date(deny_access_by_time.date_time_window?.start_date!) +
      ' to ' +
      new Date(deny_access_by_time.date_time_window?.end_date!)
    );
  }
}

export function getBehaviorActionValue(a: BehaviorAction): string {
  switch (a.type) {
    case 'ALLOWED_METHODS':
      return (a.allowed_methods as string).split(',').join(', ');
    case 'AUTO_MINIFY':
      return a.auto_minify as string;
    case 'BROWSER_CACHE_TTL':
      return a.max_ttl + '';
    case 'BYPASS_CACHE_ON_COOKIE':
      return 'Enabled';
    case 'CACHE_BEHAVIOR':
      return a.cache_behavior_value === 'CACHE' ? 'Cache' : 'Bypass';
    case 'CACHE_KEY':
      let cacheKey = JSON.parse(a.cache_key!) as CacheKey;
      let display = 'Query: ';
      if (cacheKey.query_strings.type === 'all') {
        display += 'All';
      } else if (cacheKey.query_strings.type === 'none') {
        display += 'None';
      } else if (cacheKey.query_strings.type === 'whitelist') {
        if (cacheKey.query_strings.list?.length) {
          display += 'Include - ' + cacheKey.query_strings.list.join(', ');
        }
      } else {
        if (cacheKey.query_strings.list?.length) {
          display += 'Exclude - ' + cacheKey.query_strings.list.join(', ');
        }
      }
      if (cacheKey.cookies.length > 0) {
        display += ' | Cookies: ' + cacheKey.cookies.join(', ');
      }
      if (cacheKey.headers.length > 0) {
        display += ' | Headers: ' + cacheKey.headers.join(', ');
      }
      return display;
    case 'CACHE_TTL':
      return a.max_ttl + '';
    case 'CACHED_METHODS':
      return (a.cached_methods + '').split(',').join(', ');
    case 'COMPRESSION':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'DELETE_REQUEST_HEADER':
      return a.request_header_name + '';
    case 'DELETE_RESPONSE_HEADER':
      return a.response_header_name + '';
    case 'FOLLOW_REDIRECTS':
      return 'Enabled';
    case 'GENERATE_PREFLIGHT_RESPONSE':
      return (
        'Allowed Methods: ' +
        a.response_header_value +
        ' Max-Age:' +
        a.max_ttl +
        ' Allowed Headers: ' +
        a.generate_preflight_allowed_headers
      );
    case 'GENERATE_RESPONSE':
      return 'Response page: ' + a.response_page_path;
    case 'HOST_HEADER_OVERRIDE':
      return 'Host header: ' + (a.use_domain_origin ? 'Origin domain' : a.host_header);
    case 'LARGE_FILES_OPTIMIZATION':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'URL_SIGNING':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'TRUE_CLIENT_IP':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'DENY_ACCESS':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'ORIGIN_CACHE_CONTROL':
      return a.origin_cache_control_enabled ? 'Enabled' : 'Disabled';
    case 'REDIRECT':
      return (a.redirect_source ? 'From: ' + a.redirect_source : '') + 'To: ' + a.redirect_url;
    case 'SET_CORS_HEADER':
      return a.response_header_name + ': ' + a.response_header_value;
    case 'SET_REQUEST_HEADER':
      return a.request_header_name + ': ' + a.request_header_value;
    case 'SET_RESPONSE_HEADER':
      return a.response_header_name + ': ' + a.response_header_value;
    case 'STALE_TTL':
      return 'Equals:' + a.max_ttl;
    case 'STATUS_CODE_BROWSER_CACHE':
      return statusCodeDisplay(a.status_code!) + ' TTL: ' + a.max_ttl;
    case 'STATUS_CODE_CACHE':
      return a.cache_behavior_value === 'CACHE'
        ? statusCodeDisplay(a.status_code!) + ' TTL: ' + a.max_ttl
        : 'Bypass';
    case 'STREAM_LOGS':
      return 'Sampling rate: ' + a.unified_log_sampling_rate + '%';
    case 'VIEWER_PROTOCOL':
      switch (a.viewer_protocol!) {
        case 'REDIRECT_HTTP_TO_HTTPS':
          return 'Redirect HTTP to HTTPS';
        case 'HTTP_AND_HTTPS':
          return 'HTTP and HTTPS';
        case 'HTTPS_ONLY':
          return 'HTTPS Only';
      }
      return a.viewer_protocol!;
    case 'URL_REWRITE':
      return 'from ' + a.url_rewrite_source + ' to ' + a.url_rewrite_destination;
    case 'DENY_ACCESS_BY_TIME':
      return getDenyAccessByTime(a.deny_access_by_time);
    case 'DENY_ACCESS_BY_IP':
      return a.deny_access_by_ip.ip_list.join(',');
  }
}

export type ConditionField =
  | 'http.request.domain'
  | 'http.request.path'
  | 'http.request.method'
  | 'http.response.status_code'
  | 'http.request.header'
  | 'http.response.header'
  | 'client.geo.country'
  | 'client.device.is_mobile'
  | 'http.request.query_param';

export type ConditionOperator =
  | 'eq'
  | 'ne'
  | 'lt'
  | 'gt'
  | 'le'
  | 'ge'
  | 'in'
  | 'not_in'
  | 'match'
  | 'matches_one_of'
  | 'regex'
  | 'exists'
  | 'does_not_exist';

export interface Condition {
  id: string;
  field: ConditionField;
  operator: ConditionOperator;
  value: number | string | string[] | number[] | boolean;
  field_key?: string | null;
}

export interface ConditionGroup {
  and: Condition[];
}

export interface ConditionExpression {
  or: ConditionGroup[];
}

export interface Behavior {
  id: string;
  service: string;
  name: string;
  path_pattern: string;
  behavior_actions: BehaviorAction[];
  is_default: boolean;
  complex_condition: ConditionExpression | null;
  additional_paths: string;
  order: number;
}

type TTLField = 'max_ttl' | 'unified_log_sampling_rate' | 'response_header_value';

function validateTTL(
  action: BehaviorAction,
  errors: ActionValidationError,
  fieldName: TTLField = 'max_ttl',
) {
  const rawValue = action[fieldName];
  const value = getNonNegativeValue(rawValue);
  if (value === undefined) {
    errors[fieldName] = textDict.mustBeNonNegative;
  }
}

function validateIPAddressList(ips: string[], errors: ActionValidationError, fieldName: string) {
  if (!Array.isArray(ips) || ips.length === 0) {
    errors[fieldName] = textDict.atLeastOneIpAddressRequired;
    return;
  }
  for (const ip of ips) {
    if (!validateIPAddress(ip)) {
      errors[fieldName] = `Invalid IP address: ${ip}`;
      break;
    }
  }
}

export function validateBehavior(behavior: Behavior | null): boolean {
  if (behavior === null) {
    return true;
  }

  let isValid = true;

  for (let action of behavior.behavior_actions) {
    const errors: ActionValidationError = {};

    switch (action.type) {
      case 'SET_RESPONSE_HEADER':
      case 'SET_CORS_HEADER':
        if (!action.response_header_name) {
          errors['response_header_name'] = textDict.validationErrorFieldRequired;
        }
        // For CORS "Allow-Origin" or other
        if (action.type === 'SET_CORS_HEADER') {
          if (
            action.response_header_name?.includes('Allow-Origin') &&
            !action.cors_allow_origin_domain &&
            !action.response_header_value
          ) {
            errors['response_header_value'] = textDict.validationErrorFieldRequired;
          }
          if (
            !action.response_header_value &&
            [
              'Access-Control-Allow-Credentials',
              'Access-Control-Allow-Methods',
              'Access-Control-Allow-Headers',
              'Access-Control-Expose-Headers',
            ].includes(action.response_header_name || '')
          ) {
            errors['response_header_value'] = textDict.validationErrorFieldRequired;
          }
          if (action.response_header_name?.includes('Access-Control-Max-Age')) {
            const val = parseInt(action.response_header_value || '', 10);
            if (!getNonNegativeValue(val)) {
              errors['response_header_value'] = textDict.positiveNumberIsRequired;
            }
          }
        } else {
          if (!action.response_header_value) {
            errors['response_header_value'] = textDict.validationErrorFieldRequired;
          }
        }
        break;

      case 'DELETE_RESPONSE_HEADER':
        if (!action.response_header_name) {
          errors['response_header_name'] = textDict.validationErrorFieldRequired;
        }
        break;
      case 'SET_REQUEST_HEADER':
        if (!action.request_header_name) {
          errors['request_header_name'] = textDict.validationErrorFieldRequired;
        }
        if (!action.request_header_value) {
          errors['request_header_value'] = textDict.validationErrorFieldRequired;
        }
        break;

      case 'DELETE_REQUEST_HEADER':
        if (!action.request_header_name) {
          errors['request_header_name'] = textDict.validationErrorFieldRequired;
        }
        break;
      case 'CACHE_TTL':
      case 'BROWSER_CACHE_TTL':
      case 'STALE_TTL':
        validateTTL(action, errors);
        break;

      case 'STATUS_CODE_CACHE':
      case 'STATUS_CODE_BROWSER_CACHE': {
        if (!(action.cache_behavior_value === 'BYPASS')) validateTTL(action, errors);
        break;
      }

      case 'STREAM_LOGS':
        if (!action.unified_log_sampling_rate || isNaN(Number(action.unified_log_sampling_rate))) {
          errors['unified_log_sampling_rate'] = textDict.samplingRateNumber;
        } else if (
          Number(action.unified_log_sampling_rate) < 0 ||
          Number(action.unified_log_sampling_rate) > 100
        ) {
          errors['unified_log_sampling_rate'] = textDict.samplingRateBetween0And100;
        }

        if (!action.unified_log_destination) {
          errors['unified_log_destination'] = textDict.validationErrorFieldRequired;
        }
        break;

      case 'GENERATE_PREFLIGHT_RESPONSE':
        if (!action.response_header_value?.trim()) {
          errors['response_header_value'] = textDict.validationErrorFieldRequired;
        }

        validateTTL(action, errors, 'max_ttl');

        break;

      case 'CACHE_KEY':
        if (!action.cache_key) {
          errors['cacheKey'] = textDict.atLeastOneCacheKeyComponent;
          break;
        }
        try {
          const k = JSON.parse(action.cache_key);
          if (k.headersType === 'include' && (!k.headers || k.headers.length === 0)) {
            errors['headers'] = textDict.headersRequired;
          }
          if (
            (k.query_strings?.type === 'whitelist' || k.query_strings?.type === 'blacklist') &&
            (!k.query_strings.list || k.query_strings.list.length === 0)
          ) {
            errors['queryStrings'] = textDict.queryStringsRequired;
          }
          if (k.cookiesType === 'include' && (!k.cookies || k.cookies.length === 0)) {
            errors['cookies'] = textDict.cookiesRequired;
          }
        } catch (err) {
          errors['cacheKey'] = textDict.invalidCacheKeyFormat;
        }
        break;

      case 'HOST_HEADER_OVERRIDE':
        if (!action.use_domain_origin && !action.host_header) {
          errors['host_header'] = textDict.validationErrorFieldRequired;
        }
        break;

      case 'REDIRECT':
        if (!action.redirect_url) {
          errors['redirect_url'] = textDict.validationErrorFieldRequired;
        }
        break;

      case 'URL_REWRITE':
        if (!action.url_rewrite_source) {
          errors['url_rewrite_source'] = textDict.validationErrorFieldRequired;
        }
        if (!action.url_rewrite_destination) {
          errors['url_rewrite_destination'] = textDict.validationErrorFieldRequired;
        }
        break;

      case 'DENY_ACCESS_BY_IP':
        if (!action.deny_access_by_ip) {
          errors['deny_access_by_ip'] = textDict.validationErrorFieldRequired;
        } else {
          validateIPAddressList(action.deny_access_by_ip.ip_list, errors, 'ip_list');
        }
        break;

      case 'GENERATE_RESPONSE':
        if (!action.response_page_path)
          errors['response_page_path'] = textDict.validationErrorFieldRequired;
    }

    if (Object.keys(errors).length > 0) {
      action.validationError = errors;
      isValid = false;
    } else {
      action.validationError = null;
    }
  }

  return isValid;
}

interface BehaviorState {
  list: Behavior[];
  loading: boolean;
  lastFetch: number | null;
  changeError: string | null;
  editedBehavior: Behavior | null;
  reordering: boolean;
  pendingList: Behavior[];
}

const initialState: BehaviorState = {
  list: [],
  loading: false,
  lastFetch: null,
  changeError: null,
  editedBehavior: null,
  reordering: false,
  pendingList: [],
};

const slice = createSlice({
  name: 'behaviors_new',
  initialState,
  reducers: {
    // actions => action handlers

    behaviorsReceived: (behaviors, action) => {
      behaviors.list = [
        action.payload[0].find((b: Behavior) => b.is_default),
        ...action.payload[0].filter((b: Behavior) => !b.is_default),
      ];
      behaviors.pendingList = action.payload[0].filter((b: Behavior) => !b.is_default);
      for (let b of behaviors.list) {
        if (b.complex_condition) {
          for (let or of b.complex_condition.or) {
            for (let c of or.and) {
              c.id = Math.random() + '';
            }
          }
        }
      }
      behaviors.loading = false;
      behaviors.lastFetch = Date.now();
    },

    behaviorsAdded: (behaviors, action) => {
      behaviors.list.push(action.payload[0]);
      behaviors.pendingList.push(action.payload[0]);
      behaviors.editedBehavior = null;
    },

    behaviorDeleted: (behaviors, action) => {
      const deleted = action.payload[1].payload.id;
      behaviors.list = behaviors.list.filter((s) => s.id !== deleted);
    },

    behaviorUpdated: (behaviors, action) => {
      const index = behaviors.list.findIndex((behavior) => behavior.id === action.payload[0].id);
      behaviors.list[index] = action.payload[1].payload.data;
      behaviors.editedBehavior = null;
    },

    allBehaviorsUpdated: (behaviors, action) => {
      behaviors.list = [behaviors.list[0], ...action.payload[0]];
      behaviors.pendingList = action.payload[0];
      behaviors.reordering = false;
    },

    startEdit: (behaviors, action: PayloadAction<Behavior | null>) => {
      behaviors.editedBehavior = action.payload;
      behaviors.changeError = null;
    },

    finishEdit: (behaviors) => {
      behaviors.editedBehavior = null;
      behaviors.changeError = null;
      behaviors.list.forEach((behavior) => {
        behavior.behavior_actions.forEach((action) => {
          action.validationError = undefined;
        });
      });
    },

    startReorder: (behaviors, action: PayloadAction<boolean>) => {
      behaviors.reordering = action.payload;
      if (!behaviors.reordering) {
        behaviors.pendingList = behaviors.list.filter((b: Behavior) => !b.is_default);
      }
    },

    behaviorChangeErrorHappened: (behaviors, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (resp.data['non_field_errors']) {
          behaviors.changeError = resp.data['non_field_errors'][0];
          return;
        }

        if (resp.data[0] && typeof resp.data[0] === 'string') {
          behaviors.changeError = resp.data[0];
          return;
        }

        msg = resp.data[Object.keys(resp.data)[0]];
        if (Object.keys(msg).length > 0) {
          msg = msg[Object.keys(msg)[0]];
          if (Object.keys(msg).length > 0 && typeof msg[Object.keys(msg)[0]] === 'object') {
            msg = msg[Object.keys(msg)[0]];
          }
        }
      }

      behaviors.changeError = msg;
    },

    setPendingList: (behaviors, action: PayloadAction<Behavior[]>) => {
      behaviors.pendingList = action.payload;
    },
  },
});

export const {
  behaviorsReceived,
  behaviorsAdded,
  behaviorDeleted,
  behaviorUpdated,
  behaviorChangeErrorHappened,
  startEdit,
  allBehaviorsUpdated,
  startReorder,
  setPendingList,
  finishEdit,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadBehaviors = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    onSuccess: behaviorsReceived.type,
  });

export const addBehavior = (s: string, b: Behavior) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    method: 'post',
    data: b,
    onSuccess: behaviorsAdded.type,
    onError: behaviorChangeErrorHappened.type,
  });

export const deleteBehavior = (s: string, b: string) =>
  apiCallBegan({
    id: b,
    url: `/api/v1/services/${s}/behaviors/${b}/`,
    method: 'delete',
    onSuccess: behaviorDeleted.type,
  });

export const updateBehavior = (s: string, id: string, b: Behavior) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/behaviors/${id}/`,
    method: 'put',
    data: b,
    onSuccess: behaviorUpdated.type,
    onError: behaviorChangeErrorHappened.type,
  });

export const updateAllBehaviors = (s: string, behaviors: Behavior[]) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/update_all/`,
    method: 'put',
    data: {
      behaviors: behaviors,
    },
    onSuccess: allBehaviorsUpdated.type,
    onError: behaviorChangeErrorHappened.type,
  });
