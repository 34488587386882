import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

export interface URLSigningKey {
  id?: string;
  service: string;
  name?: string;
  public_key?: string;
  private_key?: string;
  encryption_key?: string;
  provider_keys?: any;
}

export type KeysMap = {
  [key: string]: URLSigningKey[];
};

interface URLSigningKeysState {
  keys: KeysMap;
  creatingKey: URLSigningKey | null;
  error: string | null;
  loading: boolean;
}

const initialState: URLSigningKeysState = {
  keys: {},
  creatingKey: null,
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'urlSigningKeys',
  initialState,
  reducers: {
    keysLoaded: (urlSigningKeys, action) => {
      urlSigningKeys.keys[action.payload[1].payload.id] = action.payload[0];
      urlSigningKeys.loading = false;
    },

    setCreatingKey: (urlSigningKeys, action: PayloadAction<URLSigningKey | null>) => {
      urlSigningKeys.creatingKey = action.payload;
    },

    keyAdded: (urlSigningKeys, action) => {
      urlSigningKeys.keys[action.payload[0].service].push(action.payload[0]);
      urlSigningKeys.creatingKey = null;
    },

    keyGenerated: (urlSigningKeys, action) => {
      const generatedData = action.payload[0];
      urlSigningKeys.creatingKey = {
        ...urlSigningKeys.creatingKey,
        public_key: generatedData.public_key,
        private_key: generatedData.private_key,
        encryption_key: generatedData.encryption_key,
      } as URLSigningKey;
    },

    keyDeleted: (urlSigningKeys, action) => {
      const deleted = action.payload[1].payload.id;
      for (let [serviceId, _val] of Object.entries(urlSigningKeys.keys)) {
        urlSigningKeys.keys[serviceId] = urlSigningKeys.keys[serviceId].filter((s) => {
          return s.id !== deleted;
        });
      }
    },
    keyLoading: (urlSigningKeys) => {
      urlSigningKeys.loading = true;
    },
    errorHappened: (urlSigningKeys, action) => {
      urlSigningKeys.loading = false;
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (Object.keys(msg).length > 0) {
          msg = msg[Object.keys(msg)[0]];
          if (Object.keys(msg).length > 0) {
            msg = msg[Object.keys(msg)[0]];
          }
        }
      }

      urlSigningKeys.error = msg;
    },
  },
});

export const {
  keysLoaded,
  setCreatingKey,
  keyAdded,
  errorHappened,
  keyGenerated,
  keyDeleted,
  keyLoading,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadURLSigningKeys = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/url-signing-keys/`,
    onStart: keyLoading.type,
    onSuccess: keysLoaded.type,
    id: s,
    onError: errorHappened.type,
  });

export const addKey = (s: string, b: URLSigningKey) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/url-signing-keys/`,
    method: 'post',
    data: b,
    onSuccess: keyAdded.type,
    onError: errorHappened.type,
    onBackgroundTaskFinished: () => loadURLSigningKeys(s),
  });

export const generateKey = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/generate-signing-keys/`,
    method: 'post',
    onSuccess: keyGenerated.type,
    onError: errorHappened.type,
  });

export const deleteKey = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/url-signing-keys/${id}/`,
    method: 'delete',
    onSuccess: keyDeleted.type,
  });
