import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CInputGroupText, CInputGroup, CFormText } from '@coreui/react';
import IButton from './IButton';
import clipboardIcon from '../../assets/icons/clipboard.svg';

const FormTextField = (props) => {
  let validation_class = '';
  if (props.validated) {
    if (props.validation_error) {
      validation_class = 'error';
    } else if (props.showValidateSuccess) {
      validation_class = 'valid';
    }
  }

  if (props.disabled) {
    validation_class += ' disabled';
  }

  const [refReady, setRefReady] = useState(false);
  const [focused, setFocused] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handleDrop = (e) => {
      if (!props.dragAndDrop) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files) {
        if (e.dataTransfer.files.length === 1) {
          let reader = new FileReader();

          reader.readAsText(e.dataTransfer.files[0]);

          reader.onload = function () {
            props.onDrop(reader.result.trim());
          };
          e.dataTransfer.clearData();
        } else if (e.dataTransfer.files.length > 1) {
          if (props.onMultipleDrop) {
            props.onMultipleDrop(e.dataTransfer.files);
          }
        }
      }
    };

    function handleDragEvents(ref) {
      let div = ref.current;
      div.addEventListener('drop', handleDrop);
      return () => {
        div.removeEventListener('drop', handleDrop);
      };
    }
    if (!props.dragAndDrop || !refReady) {
      return;
    }
    handleDragEvents(ref);
  }, [refReady, props.dragAndDrop, props]);
  if (props.hidden) {
    return <></>;
  } else {
    return (
      <>
        <div className={props.className}>
          <div
            hidden={props.hidden || (!props.label && !props.placeholder)}
            className="form-label"
            disabled={props.disabled}
          >
            {props.label ? props.label : props.placeholder}
            <span hidden={!props.required} className={'required-asterisk'}>
              *
            </span>
          </div>
          <CInputGroup
            hidden={props.hidden}
            className={
              'form-text-field ' + validation_class + (props.type === 'textarea' ? ' textarea' : '')
            }
            style={{
              width: props.width ? props.width : null,
              paddingLeft: !props.icon ? '16px' : null,
            }}
          >
            {props.icon && (
              <CInputGroupText className="icon-container">
                <img src={props.icon} alt="" />
              </CInputGroupText>
            )}
            <div hidden={!props.unit} className="field-unit">
              {props.unit}
            </div>
            {props.type !== 'textarea' && (
              <input
                className={props.addToList ? 'list-badge' : ''}
                size={props.addToList ? props.value.length + 1 : null}
                maxLength={props.maxLength}
                id={props.id}
                name={props.name}
                aria-label={props.label}
                autoComplete={props.autocomplete}
                onChange={(e) => {
                  props.onchange(e);
                }}
                onBlur={(e) => {
                  if (props.onBlur) {
                    props.onBlur(e.currentTarget.value);
                  }
                }}
                required={props.required}
                type={props.type}
                onFocus={(e) => {
                  e.target.parentElement.classList.add('focused');
                  props.onfocus && props.onfocus(e);
                }}
                value={props.value || ''}
                onClick={() => props.onclick && props.onclick()}
                ref={(el) => {
                  if (props.focus && el && !focused) {
                    setFocused(true);
                    el.focus();
                  }
                  if (props.dragAndDrop) {
                    ref.current = el;
                    setRefReady(!!el);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && props.onEnterClick) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    if (props.onEnterClick) {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onEnterClick(props.value);
                    }
                  }
                }}
                onKeyPress={(e) => {
                  if (e.keyCode === 13) {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                min={typeof props.min !== 'undefined' ? props.min : undefined}
                readOnly={props.readOnly}
                placeholder={props.label ? props.placeholder : ''}
                disabled={props.disabled}
              />
            )}
            {props.clipboard && (
              <IButton
                className={'clipboard'}
                icon={clipboardIcon}
                onClick={() => navigator.clipboard.writeText(props.value)}
              />
            )}

            {props.type === 'textarea' && (
              <textarea
                id={props.id}
                name={props.name}
                aria-label={props.label}
                autoComplete={props.autocomplete}
                onChange={props.onchange}
                required={props.required}
                onFocus={(e) => {
                  e.target.parentElement.classList.add('focused');
                  props.onfocus && props.onfocus(e);
                }}
                value={props.value}
                rows={props.rows}
                maxLength={props.maxLength}
                ref={(el) => {
                  if (props.focus) {
                    el.focus();
                  }
                  if (props.dragAndDrop) {
                    ref.current = el;
                    setRefReady(!!el);
                  }
                }}
                placeholder={props.label ? props.placeholder : ''}
                disabled={props.disabled}
              />
            )}
          </CInputGroup>
          {props.helpText && <CFormText>{props.helpText}</CFormText>}

          {props.hint && <div className="form-field-hint">{props.hint}</div>}
          <div
            hidden={props.hideValidationIfEmpty && !props.validation_error}
            className="validation-error"
          >
            {props.validation_error}
          </div>
        </div>
      </>
    );
  }
};

FormTextField.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  onchange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation_error: PropTypes.string,
  validated: PropTypes.bool,
  value: PropTypes.any,
  rows: PropTypes.number,
  dragAndDrop: PropTypes.bool,
  id: PropTypes.string,
  onDrop: PropTypes.func,
  hidden: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
  onEnterClick: PropTypes.func,
  onBlur: PropTypes.func,
  unit: PropTypes.string,
  hint: PropTypes.string,
  min: PropTypes.number,
  onMultipleDrop: PropTypes.func,
  readOnly: PropTypes.bool,
  clipboard: PropTypes.bool,
  maxLength: PropTypes.number,
  validateRegex: PropTypes.string,
  validateFunc: PropTypes.func,
  showValidateSuccess: PropTypes.bool,
  validationMessage: PropTypes.string,
  addToList: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  hideValidationIfEmpty: PropTypes.bool,
  helpText: PropTypes.string,
  onfocus: PropTypes.func,
  onclick: PropTypes.func,
};

export default FormTextField;
