import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { ServiceOrigin } from './serviceOrigins';
import { LoadBalancer } from './loadBalancers';

type DestinationType = 'O' | 'LB' | '';

interface ServiceDomain {
  id: string;
  service: string;
  domain: string;
  origin: string;
  load_balancer: string;
  path_pattern: string;
  load_balancer_object: LoadBalancer;
  origin_object: ServiceOrigin;
}

interface Destination {
  paths: Array<{ path: string; domain: ServiceDomain }>;
  name: string;
  type: DestinationType;
  origin: string;
  loadBalancer: string;
}

type Group = {
  domain: ServiceDomain;
  destinations: { [key: string]: Destination };
  destinationsList?: Destination[];
};

type Groups = { [key: string]: Group };

interface ServiceDomainState {
  list: ServiceDomain[];
  grouped: Group[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  pendingDomain: ServiceDomain | {};
  pendingPath: ServiceDomain | {};
  pendingPathDestination: ServiceDomain | {};
  updating: boolean;
  updatingPath: boolean;
  updatingPathDestination: boolean;
}

let group = (serviceDomains: ServiceDomainState) => {
  let groups: Groups = {};
  for (let domain of serviceDomains.list) {
    let type = '';
    let key = domain.domain;
    let destination_id;
    let destinationName;
    if (domain.origin) {
      type = 'O';
      destination_id = domain.origin;
      if (domain.origin_object.type === 'CUSTOM') {
        destinationName = domain.origin_object.host;
        if (domain.origin_object.protocol === 'HTTPS' && domain.origin_object.https_port !== 443) {
          destinationName += ':' + domain.origin_object.https_port;
        } else if (
          domain.origin_object.protocol === 'HTTP' &&
          domain.origin_object.http_port !== 80
        ) {
          destinationName += ':' + domain.origin_object.http_port;
        }
      } else {
        destinationName = domain.origin_object.service_provider.name;
      }
    } else {
      type = 'LB';
      destinationName = domain.load_balancer_object.name;
      destination_id = domain.load_balancer;
    }

    if (!groups[key]) {
      groups[key] = {
        domain: domain,
        destinations: {},
      };
    }
    if (!groups[key].destinations[destination_id]) {
      groups[key].destinations[destination_id] = {
        paths: [],
        name: destinationName,
        type: type as DestinationType,
        origin: domain.origin,
        loadBalancer: domain.load_balancer,
      };
    }

    let path_pattern = domain.path_pattern;
    if (!path_pattern) {
      path_pattern = '/*';
    }
    groups[key].destinations[destination_id].paths.push({
      path: path_pattern,
      domain: domain,
    });
  }

  serviceDomains.grouped = [];

  for (const [key, val] of Object.entries(groups)) {
    val.destinationsList = [];
    for (const [key, destination] of Object.entries(val.destinations)) {
      if (destination) {
        val.destinationsList.push(destination);
      }
    }
    serviceDomains.grouped.push(val);
  }
  serviceDomains.loading = false;
  serviceDomains.lastFetch = Date.now();
};

const initialState: ServiceDomainState = {
  list: [],
  grouped: [],
  loading: false,
  lastFetch: null,
  error: null,
  pendingDomain: {},
  pendingPath: {},
  pendingPathDestination: {},
  updating: false,
  updatingPath: false,
  updatingPathDestination: false,
};

const slice = createSlice({
  name: 'serviceDomains',
  initialState,
  reducers: {
    // actions => action handlers

    serviceDomainsReceived: (serviceDomains, action) => {
      serviceDomains.list = action.payload[0];
      group(serviceDomains);
    },

    serviceDomainAdded: (serviceDomains, action) => {
      serviceDomains.list.push(action.payload[0]);
      group(serviceDomains);
      serviceDomains.error = null;
      serviceDomains.pendingDomain = {};
      serviceDomains.pendingPath = {};
      serviceDomains.updating = false;
      serviceDomains.updatingPath = false;
      serviceDomains.pendingPathDestination = {};
      serviceDomains.updatingPathDestination = false;
    },

    serviceDomainDeleted: (serviceDomains, action) => {
      const deleted = action.payload[1].payload.id;
      serviceDomains.list = serviceDomains.list.filter((p) => p.id !== deleted);
      group(serviceDomains);
      serviceDomains.error = null;
    },

    serviceDomainUpdated: (serviceDomains, action) => {
      serviceDomains.list = serviceDomains.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      group(serviceDomains);
      serviceDomains.error = null;
      serviceDomains.pendingDomain = {};
      serviceDomains.pendingPath = {};
      serviceDomains.updating = false;
      serviceDomains.updatingPath = false;
      serviceDomains.pendingPathDestination = {};
      serviceDomains.updatingPathDestination = false;
    },

    domainsError: (serviceDomains, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      serviceDomains.error = msg;
    },

    updatePendingDomain: (serviceDomains, action) => {
      serviceDomains.pendingDomain = action.payload;
      serviceDomains.updating = Object.keys(action.payload).length > 0;
      serviceDomains.error = null;
    },

    updatePendingPath: (serviceDomains, action) => {
      serviceDomains.pendingPath = action.payload;
      serviceDomains.updatingPath = Object.keys(action.payload).length > 0;
      serviceDomains.error = null;
    },

    updatePendingPathDestination: (serviceDomains, action) => {
      serviceDomains.pendingPathDestination = action.payload;
      serviceDomains.updatingPathDestination = Object.keys(action.payload).length > 0;
      serviceDomains.error = null;
      if (serviceDomains.pendingPathDestination) {
        window.location.hash =
          ('id' in serviceDomains.pendingPathDestination &&
            serviceDomains.pendingPathDestination.id) ||
          '';
      }
    },

    setError: (serviceDomains, action) => {
      serviceDomains.error = action.payload;
    },
  },
});

export const {
  serviceDomainsReceived,
  serviceDomainAdded,
  serviceDomainDeleted,
  domainsError,
  setError,
  updatePendingDomain,
  updatePendingPath,
  serviceDomainUpdated,
  updatePendingPathDestination,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadServiceDomains = (s: string) =>
  apiCallBegan({
    url: `/api/v0/services/${s}/domains/`,
    onSuccess: serviceDomainsReceived.type,
    onError: domainsError.type,
  });

export const addServiceDomain = (s: string, o: ServiceDomain) =>
  apiCallBegan({
    url: `/api/v0/services/${s}/domains/`,
    method: 'post',
    data: o,
    onSuccess: serviceDomainAdded.type,
    onError: domainsError.type,
  });

export const updateServiceDomain = (s: string, d: ServiceDomain, id: string) =>
  apiCallBegan({
    url: `/api/v0/services/${s}/domains/${id}/`,
    method: 'put',
    data: d,
    onSuccess: serviceDomainUpdated.type,
    onError: domainsError.type,
  });

export const deleteServiceDomain = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v0/services/${s}/domains/${o}/`,
    method: 'delete',
    onSuccess: serviceDomainDeleted.type,
    onError: domainsError.type,
  });
