import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface ExistingService {
  id: string;
  name: string;
}

interface ImportStatus {
  name: string;
  imported?: boolean;
  partial?: boolean;
  details?: string;
}

interface ImportedServicesStatus {
  id: string;
  name: string;
  status: 'full' | 'partial';
  domains: string[];
  origins: string[];
  behaviors: ImportStatus[];
  waf_rules: ImportStatus[];
  ratelimit_rules: ImportStatus[];
  imported_computes: ImportStatus[];
  service_id: string;
}

interface Import {
  id: number;
  created: string;
  services: string;
}

interface ImportServicesState {
  existingServices: ExistingService[];
  importedServicesStatus: ImportedServicesStatus[];
  imports: Import[];
  limit: number;
  count: number;
  error: string | null;
  step: number;
}

const initialState: ImportServicesState = {
  existingServices: [],
  importedServicesStatus: [],
  imports: [],
  limit: 5,
  count: 0,
  error: null,
  step: 1,
};

const slice = createSlice({
  name: 'importServices',
  initialState,
  reducers: {
    existingServicesReceived: (importServices, action) => {
      importServices.existingServices = action.payload[0];
    },

    servicesImported: (importServices, action) => {
      importServices.importedServicesStatus = action.payload[0];
      importServices.step = 3;
    },

    importsReceived: (importServices, action) => {
      importServices.imports = action.payload[0].list;
      importServices.count = action.payload[0].count;
      importServices.limit = action.payload[0].limit;
    },

    showReport: (importServices, action) => {
      importServices.importedServicesStatus = JSON.parse(action.payload.services);
    },

    onError: (importServices, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      importServices.error = msg;
    },

    setStep: (importServices, action) => {
      importServices.error = '';
      importServices.step = action.payload;
    },
  },
});

export const {
  existingServicesReceived,
  servicesImported,
  importsReceived,
  showReport,
  onError,
  setStep,
} = slice.actions;

export default slice.reducer;

export const loadExistingServices = (p: string) =>
  apiCallBegan({
    url: `/api/v1/account-providers/${p}/list_existing_services/`,
    onSuccess: existingServicesReceived.type,
    onError: onError.type,
  });

export const startImportServices = (p: string, serviceIDs: number[]) =>
  apiCallBegan({
    url: `/api/v1/account-providers/${p}/import_service/`,
    data: {
      ids: serviceIDs,
    },
    method: 'post',
    onSuccess: servicesImported.type,
    onError: onError.type,
  });

export const loadImports = () =>
  apiCallBegan({
    url: `/api/v1/imports`,
    onSuccess: importsReceived.type,
  });
